import { useMemo } from "react";

export default function Example() {
  const randomIndex = Math.round(Math.random());
  const colorVariants = {
    blue: 'from-teal-500 to-cyan-500',
    purple: 'from-violet-500 to-fuchsia-500',
  }
  const tracksUrls =  useMemo(() => [
    {
      gradient: "blue",
      url: "https://open.spotify.com/embed/track/2FKJgnoFsYHsNtZvNF6gF1?utm_source=generator"
    },
    {
      gradient: "purple",
      url: "https://open.spotify.com/embed/track/5YGoexJuunc44BgBiJ4u1v?utm_source=generator"
    },
  ], [])
  const track = useMemo(() => tracksUrls[randomIndex], [randomIndex, tracksUrls])
  return (
    <>
      <main className={`${colorVariants[track.gradient]} grid min-h-full place-items-center bg-gradient-to-r px-6 py-24 sm:py-8 lg:px-8`}>
        <div className="text-center">
          <p className="text-base font-semibold text-white">404</p>
          <h1 className="mt-4 text-3xl text-white font-bold tracking-tight sm:text-5xl">Page not found</h1>
          <p className="mt-6 text-base text-white leading-7">Sorry, we couldn’t find the page you’re looking for. But you can listen to one of our tracks 😎 </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <iframe title='spotify-player' className="border-r-8 border-none" src={track.url} width="100%" height="352" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
          </div>
        </div>
      </main>
    </>
  )
}
